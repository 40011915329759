import React, { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getEvents } from 'services/hasura';
import { Search } from 'tutadoo-design-system/src/components/pages/Search';
import { uniquePair } from 'utils/array';
import slugify from 'utils/slugify';
import { navigate } from 'gatsby';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { ProgressiveImage } from '../ProgressiveImage';

const gquery = getEvents(null, true);

const smartUrlConvert = (options) => {
  const final = [];
  if (options.prices?.length) {
    final.push(options.prices.map(slugify).join('+'));
  } else {
    final.push('all');
  }
  if (options.categories?.length) {
    final.push(`${options.categories.map(slugify).join('+')}--events`);
  }
  if (options.organizers?.length) {
    final.push(`by-${options.organizers.map(slugify).join('+')}`);
  }
  return final.length > 0
    ? decodeURI(final.join('--')).replace(/ /g, '-').replace(/&/g, 'and')
    : '';
};

const filterToVariables = (selectedFilters) => {
  const updatedFilters = { categories: '.', organizers: '.', prices: '.' };
  updatedFilters.categories =
    selectedFilters.categories?.length > 0
      ? `(\\y${selectedFilters.categories?.join('\\y|\\y')}\\y)`
      : '.';
  updatedFilters.organizers =
    selectedFilters.organizers?.length > 0
      ? `(\\y${selectedFilters.organizers.join('\\y|\\y')}\\y)`
      : '.';
  updatedFilters.prices =
    selectedFilters.prices?.length > 0 ? `(\\y${selectedFilters.prices.join('\\y|\\y')}\\y)` : '.';
  return updatedFilters;
};

export function SearchPage({ scrollPosition }) {
  const now = new Date();
  const future = new Date();
  now.setMonth(now.getMonth() - 12);
  future.setMonth(now.getMonth() + 15);
  const [busy, setBusy] = useState(true);
  const [filter, setFilter] = useState<filters.Events>({
    filterSearchString: '%%',
    filterFrom: now.toISOString(),
    filterTo: future.toISOString(),
    filterLocation: { state: 'Nairobi', country: 'Kenya' },
    categories: '.',
    organizers: '.',
    prices: '.'
  });

  const [lastData, setLastData] = useState({ categories: [], organizers: [] });

  const [doSearch, { data, refetch, loading }] = useLazyQuery(gquery, {
    variables: filter
  });

  useEffect(() => {
    doSearch();
  }, [filter]);

  const onFilterChange = useCallback(
    (selectedFilters) => {
      if (loading) return;
      const updatedFilters = filterToVariables(selectedFilters);
      const nextFilter = {
        ...filter,
        ...updatedFilters
      };
      setFilter(nextFilter);
      navigate(`/s/${smartUrlConvert(selectedFilters)}`);
    },
    [loading, setFilter]
  );

  const onSearch = (v) => {
    refetch({ ...filter, filterSearchString: `%${v.searchString}%` });
  };

  useEffect(() => {
    setBusy(loading);
    if (loading || !data) {
      return;
    }
    setBusy(false);
    setLastData(data);
  }, [data, loading]);

  return (
    <Search
      ImageComponent={(props) => <ProgressiveImage scrollPosition={scrollPosition} {...props} />}
      basePath=""
      busy={busy}
      locations={[{ state: 'Nairobi', country: 'Kenya' }]}
      events={(data || lastData)?.events}
      onApplyFilter={onFilterChange}
      onFilterChange={onFilterChange}
      onSearch={onSearch}
      filterProps={{
        filterFields: {
          prices: [
            { label: 'Free', value: 'free' },
            { label: 'Paid', value: 'paid' }
          ],
          categories: lastData
            ? uniquePair(lastData.categories.map(({ label, value }) => ({ label, value })))
            : [],
          organizers: lastData
            ? uniquePair(
                lastData.organizers.map(({ organizer: { label, value } }) => ({
                  label: label.trim(),
                  value: value.trim()
                }))
              )
            : []
        }
      }}
      total={data?.events_aggregate?.aggregate?.count || ''}
    />
  );
}

export default trackWindowScroll(SearchPage);
