import { SEO } from 'components/Seo';
import Layout from 'components/Layout';
import { SearchPage } from 'components/pages/search';
import { FiltersProvider } from 'tutadoo-design-system/src/components/Filter';
import { isBrowser } from 'utils/browser';
import ErrorPage from 'pages/404';

const smartUrlDeconstruct = (basePath, url: string) => {
  const filters = {
    filterSearchString: '',
    categories: [],
    organizers: [],
    prices: []
  };
  if (isBrowser) {
    const splitted = url.split(basePath);
    const unsplit = splitted[1].split('--', -1);
    if (unsplit.length > 1) {
      if (!unsplit[0].startsWith('all')) {
        filters.prices =
          unsplit[0].length > 0
            ? unsplit[0].split('+').map((v) => v.split('-').join(' ').split('and').join('&'))
            : [];
      }
      unsplit.slice(1).forEach((item) => {
        if (item.startsWith('by-')) {
          filters.organizers = item
            .split('by-')[1]
            .split('+')
            .map((v) => v.split('-').join(' '));
        } else if (item !== 'events') {
          filters.categories = item
            .split('+')
            .map((v) => v.split('-').join(' ').split('and').join('&'));
        }
      });
    }
  }

  return filters;
};

function Page(props) {
  if (props.location.pathname.startsWith('/s')) {
    const selectedFilters = smartUrlDeconstruct('/s/', props.location.pathname || '');
    return (
      <Layout noPadding>
        <FiltersProvider initialFilters={selectedFilters}>
          <SearchPage {...props} />
        </FiltersProvider>
      </Layout>
    );
  }
  return <ErrorPage />;
}
export default Page;

export const Head = ({ location }) => {
  return (
    <SEO title={`Tutadoo | ${location.pathname.startsWith('/s') ? 'Browse Events' : '404'}`} />
  );
};
