import { pipe, groupBy, prop, map, pluck, flatten, uniq, toPairs, zipObj } from 'ramda';

export const uniquePair = pipe(
  groupBy(prop('value')),
  map(pluck('label')),
  map(flatten),
  map(uniq),
  map((v) => v[0]),
  toPairs,
  map(zipObj(['label', 'value']))
);
