import React from 'react';
import Layout from 'components/Layout';
import ErrorFallback from 'components/ErrorFallback';
import { SEO } from 'components/Seo';

const ErrorPage = () => (
  <Layout noPadding>
    <ErrorFallback resetError={() => {}} />
  </Layout>
);

export default ErrorPage;

export const Head = () => {
  return <SEO title="Tutadoo | 404" />;
};
